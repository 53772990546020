<template>
  <div class="print-content" id="print-area">
    <div id="first-print">
      <p style="text-align: center;position: relative;font-size: 60px;" class="print-header">商品购销合同</p>
      <p class="mb20 print-title" style="text-align: center;font-size: 30px;">合同编号：<span class="word-input" :class="contractNumber? 'no-width':'no-word'">{{contractNumber}}</span></p>
      <p class="mb20 page-break" style="text-align: center;font-size: 30px;">签订日期：<span class="word-input" :class="form.date? 'no-width':'no-word'">{{form.date}}</span></p>
    </div>

    <div id="second-print">
    <p class="printContent">本商品购销合同（以下简称“本合同”）由下列双方于<span class="word-input" :class="signAddress? 'no-width':'no-word'">{{signAddress}}</span>签订：</p>
    
    <p>甲方（卖方）：<span class="word-input" :class="sellCompany.fullNameOfEnterprise? 'no-width':'no-word'" >{{sellCompany.fullNameOfEnterprise}} </span> </p>
    
    <p>组织机构代码证/统一社会信用代码：<span class="word-input" :class="sellCompany.unifiedSocialCreditCode? 'no-width':'no-word'">{{sellCompany.unifiedSocialCreditCode}}</span></p>
    
    <p>注册地址：<span class="word-input" :class="sellCompany.registerAddress? 'no-width':'no-word'">{{sellCompany.registerAddress}}</span> </p>
    
    <p>法定代表人或负责人：<span class="word-input" :class="sellCompany.legalPerson? 'no-width':'no-word'">{{sellCompany.legalPerson}}</span></p>
    
    <p>乙方（买方）：<span class="word-input" :class="buyerCompany.fullNameOfEnterprise? 'no-width':'no-word'">{{buyerCompany.fullNameOfEnterprise}}</span> </p>
    
    <p>组织机构代码证/统一社会信用代码：<span class="word-input" :class="buyerCompany.unifiedSocialCreditCode? 'no-width':'no-word'">{{buyerCompany.unifiedSocialCreditCode}}</span></p>
    
    <p>注册地址：<span class="word-input" :class="buyerCompany.registerAddress? 'no-width':'no-word'">{{buyerCompany.registerAddress}}</span> </p>
    
    <p>法定代表人或负责人：<span class="word-input" :class="buyerCompany.legalPerson? 'no-width':'no-word'">{{buyerCompany.legalPerson}}</span>  </p>
    
    <p>
      买卖双方遵循平等、自愿和诚实信用的原则，根据法律法规等规定、《加佳数字供应链平台系统用户服务协议》不时制定并公布的与现货交易、支付结算、交收过户等相关的规则、规定或公告等及其不时的修订和变更 (以下简称“运营管理制度”)，经协商一致，达成本合同。
    </p>
    
    <p>
      在本合同中，“卖方”和“买方”统称为“双方”，各自被称为“一方”。
    </p>
    
    <p>
      第一条   合同标的
    </p>
    
    <p>
      1. 双方知晓并同意加佳数字供应链平台系统（以下简称“加佳供应链系统”）的运营管理制度并按本合同的条款和条件购买和销售商品，商品的名称、数量等信息详见本合同<span >附件一</span>。
    </p>
    
    <p>
      2. 卖方承诺和保证对其所注册的数字仓单及/或其所载商品拥有合法、完整和唯一的所有权，该等数字仓单及/或其所载商品上不存在任何担保物权或权利瑕疵。
    </p>
    
    <p>
      第二条 结算及交收流程
    </p>
    
    <p>
      1. 双方同意于本合同项下以数字仓单进行线上交收，并应按以下方式进行结算与交收：
    </p>
    
    <p>
      (1) 卖方应在合同确认后尽快在加佳供应链系统注册与合同约定信息相符的数字仓单。
    </p>
    
    <p>
      (2) 买方根据合同价格按以下方式支付合同最终实际金额（以下称“全额货款”）：买方通过其开户银行向卖方线上或线下支付全额货款，包括但不限于电汇、信用证、银行承兑汇票、商业承兑汇票等。 卖方对货款支付有异议的，应及时向买方提出异议。
    </p>
    
    <p>
      (3) 卖方将交收商品对应的数字仓单通过加佳供应链系统由卖方过户至买方名下，并通知相应的指定交收机构，即视为卖方已履行其交付义务。
    </p>
    
    <p>
      (4) 卖方以数字仓单进行交收，仓单过户完成后，买方应在十（10）个工作日内至指定交收仓库核实仓单载明商品的质量、数量或重量等信息。如买方对交收商品有异议的，应按运营管理制度在规定时间内向卖方提出异议，提供有关证明文件并保证商品的完整性，否则视为买方对交收商品无异议
    </p>
    
    <p>
      2. 为保证买卖双方对加佳数字供应链系统规则理解的一致性，双方认可以下表述：数字仓单所载商品的所有权及毁损、灭失风险自数字仓单完成过户且加佳数字供应链系统通知到达指定交收机构时由卖方转移给买方。
    </p>
    
    <p>
      3. 双方同意本合同项下数字仓单过户统一通过加佳供应链系统进行。
    </p>
    
    <p>
      第三条   质量、数量
    </p>

    <p>
      1. 卖方承诺及保证其提交的数字仓单及/或其所载交收商品的名称、质量、数量、品牌（厂家）等均应符合加佳供应链系统相关运营管理制度的规定及本合同的约定。
    </p>

    <p>
      2. 买卖双方对于交收商品的质量、数量、品牌（厂家）等存在争议的，可委托第三方质检机构进行检验。买卖双方可依据质检机构出具的检验报告协商处理争议。检验费由提出质量异议的一方预付，并由最终责任方承担。
    </p>

    </div>
    <div id="third-print">
    
    <p>
      第四条   违约责任
    </p>
    
    <p>
      1. 合同任何一方不履行本合同的义务或履行义务不符合约定的即构成违约。本合同对违约责任已有约定的，按照约定承担；没有约定的，应依法承担 违约责任，包括继续履行、采取补救措施或者赔偿损失等。
    </p>
    
    <p>
      2. 具有下列情形之一的，构成违约：
    </p>
    
    <p >
      (1) 在规定期限内，买方未按本合同约定支付全额商品货款；
    </p>

    <p>
      (2) 在规定期限内，卖方未将与本合同约定一致的有效数字仓单过户至买方名下；
    </p>
    
    <p>
      (3) 违反相关运营管理制度或本合同约定的其他行为。
    </p>

    <p>
      3. 任何一方具有本条所述违约情形的，根据相关运营管理制度、本合同的约定和/或双方协议一致的结果进行处理。
    </p>
    
    <p>
      4. 尽管有本合同其他规定，本条规定的效力不受本合同终止或解除的影响。
    </p>
    
    <p>
      第五条   合同转让、变更、终止与解除
    </p>
    
    <p>
      1. 合同任何一方不得将本合同或本合同项下的权利或义务转让、分配、转许可、抵押或质押给第三方，但另一方事先明确书面同意的除外。
    </p>
    
    <p>
      2. 经双方协商一致，可变更或解除本合同。合同变更和解除须采取书面形式。双方协商变更或解除本合同的，应当在该等变更或解除之后及时在加佳供应链系统完成取消合同的操作，并保留相关资料。
    </p>
    
    <p>
      3. 除本合同另有约定外，出现以下任一情形的，本合同终止：
    </p>
    
    <p>
      (1) 买卖双方就本合同终止书面协商一致；
    </p>
    
    <p>
      (2) 根据法律规定、加佳供应链系统的运营管理制度或本合同的约定，买方或卖方单方解除本合同；
    </p>
    
    <p>
      (3) 双方在本合同项下的权利义务均已履行完毕。
    </p>
    
    <p>
      第六条   不可抗力
    </p>
    
    <p>
      1.  “不可抗力”是指任何超出本合同双方控制的，不能预见或防范、不能避免并不能克服的，并妨碍任何一方全部或部分履行本合同的所有事件，包括但不限于地震、台风、水灾、火灾、暴动、罢工、战争、恐怖袭击、政府行为、法律规定或适用的变更、国际或国内的禁令或限制以及停电、系统故障、技术故障、电子故障等。
    </p>
    
    <p>
      2. 本合同任何一方因不可抗力不能履行协议的，根据不可抗力的影响，经与另一方协商一致后，可以部分或者全部免除责任，但(1)法律另有规定的除外；(2)该方迟延履行或违约后发生不可抗力的，不能免除责任。
    </p>
    
    <p>
      3. 本合同任何一方因不可抗力不能部分或全部履行本合同项下义务的，应当立即通知另一方，说明不可抗力的发生日期、事件性质，预计持续的时间 及对该方履行本合同的影响，并应当自不可抗力发生之日起五个工作日内提供证明。
    </p>
    
    <p>
      4. 对不可抗力所造成的影响，双方应及时协商解决办法和补救措施。因不可抗力不能部分或全部履行本合同项下义务的一方，应尽力采取合理措施减轻可能给对方造成的损失，否则应对由此而扩大的损失承担赔偿责任。
    </p>
    
    <p>
      第七条   保密
    </p>
    
    <p>
      买卖双方对于本合同的签订、内容及在履行本合同期间所获知的另一方的商业秘密负有保密义务。非经对方书面同意，任何一方不得向第三方披露、给予或转让该等保密信息，但按照运营管理制度或甲方、乙方与加佳供应链系统之间的合同规定向加佳供应链系统披露的不受此限。
    </p>
    
    <p>
      第八条   法律适用及争议解决
    </p>

    <p class="page-break">
      本合同适用中华人民共和国法律。凡因本合同引起的或与本合同有关的任何争议、纠纷、分歧或索赔(包括但不限于本合同的存续、效力、解释、履行、违反或终止，或因本合同引起的或与之相关的任何非合同性争议)，如双方未能通过协商一致进行解决，任何一方均有权向本合同签订地人民法院提起诉讼。
    </p>

  </div>
  <div id="fourth-print">

    <p>
      第九条   其他事项
    </p>
    
    <p>
      1. 双方同意本合同采用电子交易合同形式。双方知悉并同意根据加佳供应链系统相关运营管理制度，本合同项下交易经双方确认后，即生成本合同，并对本合同双方产生法律效力和约束力。本合同的签署生效时间以加佳供应链系统记载的时间为准。双方采用电子签名的形式对合同内容进行确认，且在此不可撤销地同意和确认该等确认为其真实意思表示并以其自身名义作出。
    </p>
    
    <p>
      2. 双方在此不可撤销地同意和确认通过前述方式订立的本合同与纸质合同具有同等效力并对合同双方具有法律约束力，双方不得以其账户密码等账户信息被盗用、无纸质合同、电子签名或其他理由否认已订立的本合同的效力或不按照本合同履行相关义务。如对本合同真伪或本合同的内容有任何疑问，双方同意以加佳供应链系统记录的本合同的内容为准。
    </p>
    
    
    <p>
      3. 加佳供应链系统中记录的买卖双方就有关本合同的履行达成的交易信息以及本合同的附件构成本合同不可分割的组成部分，与本合同正文具有同等效力。
    </p>
    
    
    <p>
      4. 除法律、法规另有规定或当事人另有约定外，如本合同中的任何条款无论因何种原因完全或部分无效或不具有执行力，则应认为该条款可与本合同相分割，并可被尽可能接近各方意图的、能够保留本合同要求的经济目的的、有效的新条款所取代，而且，在此情况下，本合同的其他条款仍然完全有效并具有约束力。
    </p>
    
    
    <p>
      本协议由下述双方签署，双方确认，在签署本协议时，双方已就全部条款（包括附件）进行了充分的讨论和详细的说明，双方对协议的全部条款均无疑义，并对当事人有关权利义务和责任限制或免除条款的法律含义有准确无误的理解。
    </p>
    
    <div class="page-break" style="display: flex;justify-content: space-between;">
      <div>甲方：（签字/盖章）</div>
      <div>乙方：（签字/盖章）</div>
    </div>
    </div>

    <div id="fifth-print">
    <p class="head-title">附件一：商品信息</p>
    
    <div class="container page-break" >
      <div class="content">
        <div class="row-fst">
          <div class="left">
            <div><span>卖方:</span><span>{{salerInfo.name}}</span></div>
            <div><span>电话:</span><span>{{salerInfo.contactNumber}}</span></div>
            <div><span>传真:</span><span>{{salerInfo.fax}}</span></div>
            <div><span>地址:</span><span>{{salerInfo.address}}</span></div>
          </div>
          <div class="right">
            <div><span>买方:</span><span>{{buyerInfo.name}}</span></div>
            <div><span>电话:</span><span>{{buyerInfo.contactNumber}}</span></div>
            <div><span>传真:</span><span>{{buyerInfo.fax}}</span></div>
            <div><span>地址:</span><span>{{buyerInfo.address}}</span></div>
          </div>
        </div>
        <div class="row-sec">
          <div class="row">
            <div class="cell">合同编号</div>
            <div class="cell">签约时间</div>
            <div class="cell">签约方式</div>
            <div class="cell">总重量</div>
            <div class="cell">总金额</div>
          </div>
          <div class="row">
            <div class="cell">{{contractNumber}}</div>
            <div class="cell">{{contractDate}}</div>
            <div class="cell">/</div>
            <div class="cell">{{totalWeight}}</div>
            <div class="cell">{{totalAmount}}</div>
          </div>
        </div>
        <div style="border-top: 1px solid #333;border-right: 1px solid #333;line-height: 30px;">一、产品名称、规格、数量、单价、金额、供货时间及地点等。</div>
        <div class="row-sec">
          <div class="row">
            <div class="cell">产品名称</div>
            <div class="cell" style="width: 50px;">单位</div>
            <div class="cell">品牌</div>
            <div class="cell" style="width: 50px;">数量</div>
            <div class="cell" style="width: 80px;">含税单价(元)</div>
            <div class="cell" style="width: 80px;">金额(元)</div>
            <div class="cell">仓库</div>
          </div>
          <div class="row" v-for="(item,index) in goodsData" :key="index">
            <div class="cell">{{item.commodityName}}</div>
            <div class="cell">{{item.weightUnit}}</div>
            <div class="cell">{{item.productorName}}</div>
            <div class="cell">{{item.weight}}</div>
            <div class="cell">{{item.price}}</div>
            <div class="cell">{{item.amountOfMoney}}</div>
            <div class="cell" style="width:200px;">{{item.warehouseName}}</div>
          </div>
        </div>
        <div style="border-top: 1px solid #333;border-right: 1px solid #333;line-height: 30px;">金额（人民币）：{{price}}</div>
      </div>
      
      <p>
        注：1.本合同约定交货溢装百分比为6.00%，短装百分比为6.00%。本合同项下商品总货款将在前述溢装/短装范围内根据双方认可的交收量进行最终结算。
      </p>
    </div>
    </div>
    <div id="six-print">
    <p class="head-title">附件二：其他说明</p>
    
    <p v-if="showMark">{{textarea?textarea:type==="print"?'':'无'}}</p>
    <el-input
      v-else
      class="mark-area"
      type="textarea"
      :rows="4"
      placeholder="请输入要补充的条款，没有时，合同中将显示为“无”"
      v-model.trim="textarea"
      maxlength="4000"
      ref="mark"
      >
    </el-input>
    </div>
  </div>
</template>

<script>
import printJS from 'print-js'
export default {
  
  name: 'contractTemplateCon',
  props:{
    type:{
      type: String,
      default: ''
    },
    sellCompany:{
     type:Object,
      default:()=>({})
    },
    buyerCompany: {
     type:Object,
      default:()=>({})
    },
    contractNumber:{
      type:String,
      default:''
    },
    form:{
      type:Object,
      default:()=>({})
    },
    signAddress:{
      type:String,
      default:''
    },
    sellerCompanyName:{
      type:String,
      default:''
    },
    enterpriseInfo:{
      type:Object,
      default:()=>({})
    },
    salerInfo:{
      type:Object,
      default:()=>({})
    },
    buyerInfo:{
      type:Object,
      default:()=>({})
    },
    contractDate:{
      type:String,
      default:''
    },
    totalWeight:{
      type:Number,
      default:''
    },
    totalAmount:{
      type:Number,
      default:''
    },
    goodsData:{
      type:Array,
      default:()=>([])
    },
    price:{
      type:String,
      default:''
    },
    contenteditable:{
      type:Boolean,
      default:false
    },
    optionsHtml:{
      type:String,
      default:''
    }
  },
  data(){
    return{
      textarea:'',
      showMark: true,
    }
  },
  methods:{
    getFocus(){
      this.showMark = false
      setTimeout(() => {
        this.$refs.mark.focus()
      }, 100);

    },
    print(){
      this.showMark = true
      setTimeout(() => {
        let style="@page {}"+"@media print {  p {  height: auto !important; } }"
        printJS({
          printable: 'print-area',
          type: 'html',
          targetStyles: ['*'],
          maxWidth: '1000',
          style:style,
          font_size: 'auto'
        }) 
      }, 100);
    },
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width:1300px){
  .print-header{
    margin:250px 0 380px !important;
  }
}
@media screen and (min-width:1300px){
  .print-header{
    margin:250px 0 380px !important;
  }
}
.repatBr{ display: none !important; }
.container {
  width: 700px;
  // margin: 0 auto;
  // font-family: "arial,sans-serif", "宋体", "微软雅黑", "黑体";
  // color: #444;
  letter-spacing: 0.01px;
  .container-title {
    font-size: 28px;
    line-height: 58px;
    text-align: center;
    color: #333;
    font-weight: bold;
  }
  .content {
    border: 1px solid #333;
    border-right: 0;
    .row-fst {
      display: flex;
      .left,
      .right {
        flex: 1;
        border-right: 1px solid #333;
        div {
          line-height: 30px;
        }
      }
    }
    .row-sec {
      display: table;
      width: 100%;
      text-align: center;
      .row {
        display: table-row;
        .cell {
          display: table-cell;
          border-top: 1px solid #333;
          border-right: 1px solid #333;
          line-height: 30px;
        }
      }
    }
    .row-last {
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      line-height: 30px;
    }
  }
}
.no-word{
  display: inline-block;
  width: 300px;
}
.no-width{
  width: auto;
}
.page-break{
  page-break-after: always;
}
.word-input{

  border-bottom: 1px solid;
  vertical-align: bottom;
  display: inline-block;
  text-align: left;
}
.print-content{
  width: 210mm;
  padding:0 10px;
  p{
    padding: 7px 8px 6px 2px;
    text-align: justify;
  }
}
.head-title{
  font-weight: 500;
  font-size: 18px;
}
</style>